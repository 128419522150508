<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12 mb-0">
                <div class="card">
                    <div class="card-body">
                        <div class="float-sm-left mr-3">
                            <span class="">
                                 <span>Patient Name : </span>
                                <span class="font-14 font-weight-semibold pr-3">{{orderDetail.patient.identity.surname}} {{orderDetail.patient.identity.othernames}}</span>
                            </span>
                            <router-link to="/pharmacy/orders" class="btn btn-info"
                                ><i class="mdi mdi-arrow-left-circle-outline mr-1"></i> Back
                            </router-link>
                        </div>
                        <div class="float-right" v-if="orderDetail.status === 'PENDING_CONFIRMATION'">
                            <b-button v-b-modal.modal-sm variant="success">Print order details</b-button>
                            <b-modal id="modal-sm" size="sm" title="Order details" title-class="font-18" hide-footer>
                              <div id="my-modal-for-print">
                                  <div class="row">
                                  <div class="col-lg-6">
                                      <label>Patient Name</label>
                                      <p class="font-14 font-weight-semibold"> {{orderDetail.patient.identity.surname}} {{orderDetail.patient.identity.othernames}}</p>
                                  </div>
                                  <div class="col-lg--6">
                                      <label>Address</label>
                                      <p class="font-12 font-weight-semibold">{{orderDetail.address.streetAddress}} <br />{{orderDetail.address.city}}</p>
                                  </div>
                              </div>
                                <div class="my-3">
                                    <qrcode-vue :value="qrCodeText" :text="qrCodeText" :size="240"  :options="qrcodeOptions"></qrcode-vue>
                                </div>
                              </div>


                                <b-button variant="primary" @click="printOrderDetails">Print</b-button>
                            </b-modal>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col-->

            <div class="col-lg-12 col-xl-12 mx-0">
                <div class="card mb-2">
                    <div class="card-body">
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title> <i class="mr-1"></i>List of products </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Product Name</th>
                                                <th>Image</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="p in listOfProducts" :key="p.id">
                                                <td>{{ p.product.id }}</td>
                                                <td>
                                                    <router-link
                                                            class="text-primary mr-2"
                                                            style="cursor: pointer; border-bottom: 2px dotted #029f96"
                                                            :to="'/product/' + p.product.id"
                                                    >{{ p.product.productName }}</router-link
                                                    >
                                                </td>
                                                <td><img :src="p.product.mainImage.thumbnailUrl" height="30" width="40" /></td>
                                                <td>
                                                    <span class="badge badge-outline-danger">{{ p.quantity }}</span>
                                                </td>
                                                <td>
                                                    <span class="badge badge-outline-primary p-1">{{ p.product.costPrice }}</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                            <b-tab>
                                <template v-slot:title> <i class="mr-1"></i><span>Prescription Drugs</span> <span class="badge badge-outline-dark text-dark rounded-circle">{{listOfProducts.length}}</span></template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Product Name</th>
                                                <th>Image</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="p in listOfProducts" :key="p.id">
                                                <td>{{ p.product.id }}</td>
                                                <td>
                                                    <router-link
                                                            class="text-primary mr-2"
                                                            style="cursor: pointer; border-bottom: 2px dotted #029f96"
                                                            :to="'/product/' + p.product.id"
                                                    >{{ p.product.productName }}</router-link
                                                    >
                                                </td>
                                                <td><img :src="p.product.mainImage.thumbnailUrl" height="30" width="40" /></td>
                                                <td>
                                                    <span class="badge badge-outline-danger">{{ p.quantity }}</span>
                                                </td>
                                                <td>
                                                    <span class="badge badge-outline-primary p-1">{{ p.product.costPrice }}</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-box-->
                </div>
                <div class="card mb-2">
                    <div class="card-body">
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title> <i class="mr-1"></i>Order Details </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>Order Number</th>
                                                    <th>Patient</th>
                                                    <th>Order Status</th>
                                                    <th>Rider Name</th>
                                                    <th>Rider Phone No.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ orderDetail.orderNumber }}</td>
                                                    <td>
                                                        <router-link
                                                            class="text-primary mr-2"
                                                            style="cursor: pointer; border-bottom: 2px dotted #029f96"
                                                            :to="'/patients/' + orderDetail.patient.id"
                                                            >{{ orderDetail.patient.identity.surname }}
                                                            {{ orderDetail.patient.identity.othernames }}</router-link
                                                        >
                                                    </td>
                                                    <td>
                                                        <span
                                                            class="p-1 badge"
                                                            :class="{
                                                                'badge-info': orderDetail.status === 'ONGOING',
                                                                'badge-danger': orderDetail.status === 'DELAYED',
                                                                'badge-secondary': orderDetail.status === 'PENDING_CONFIRMATION',
                                                                'badge-primary': orderDetail.status === 'COMPLETED',
                                                                'badge-success': orderDetail.status === 'ARRIVED_AT_PICKUP',
                                                                'badge-danger': orderDetail.status === 'CUSTOMER_CANCELLED',
                                                                'badge-danger': orderDetail.status === 'SYSTEM_CANCELLED',
                                                            }"
                                                        >
                                                            {{ orderDetail.status }}</span
                                                        >
                                                    </td>
                                                    <td v-if="orderDetail.isConfirmed">
                                                        <router-link
                                                            class="text-primary mr-2"
                                                            style="cursor: pointer; border-bottom: 2px dotted #029f96"
                                                            :to="'/rider/' + orderDetail.rider.id"
                                                            >{{ orderDetail.rider === null ? '----' : orderDetail.rider.identity.surname }}
                                                            {{
                                                                orderDetail.rider === null ? '----' : orderDetail.rider.identity.othernames
                                                            }}</router-link
                                                        >
                                                    </td>
                                                    <td v-if="orderDetail.isConfirmed">
                                                        <router-link
                                                            class="text-primary mr-2"
                                                            style="cursor: pointer; border-bottom: 2px dotted #029f96"
                                                            :to="'/rider/' + orderDetail.rider.id"
                                                            >{{
                                                                orderDetail.rider === null ? '----' : orderDetail.rider.identity.phoneNumber
                                                            }}</router-link
                                                        >
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                            <b-tab>
                                <template v-slot:title> <i class="mr-1"></i>Prescription History</template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-hover table-centered mb-0">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Prescription Name</th>
                                                    <th>Qty of tablet</th>
                                                    <th>Dosage duration</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="p in prescription" :key="p.id">
                                                    <td>{{ p.id }}</td>
                                                    <td>App design and development</td>
                                                    <td>{{ p.quantityOfTablets }}</td>
                                                    <td>{{ p.dosageDuration }}</td>
                                                    <td>
                                                        <span class="badge p-1" :class="p.status === 'PENDING' ? 'badge-danger' : 'badge-primary'">{{
                                                            p.status
                                                        }}</span>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                            <b-tab>
                                <template v-slot:title> <i class="mdi mdi-settings-outline mr-1"></i>Patient History </template>
                                <div class="table-responsive mb-0">
                                    <table class="table table-hover table-centered mb-0">
                                        <thead class="thead-light">
                                            <tr>
<!--                                                <th>Original price</th>-->
<!--                                                <th>Amount sold at</th>-->
<!--                                                <th>Discounted price</th>-->
<!--                                                <th>Qty</th>-->
<!--                                                <th>Status</th>-->
<!--                                                <th>Action</th>-->
                                                <th>Date</th>
                                                <th>Name</th>
                                                <th>Phone Number</th>
                                                <th>Age</th>
                                                <th>Dispenser</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-if="orderDetail.patient">
                                              <td><span class="font-12 font-weight-bold text-dark">{{ orderDetail.orderedAt | moment("MMMM Do YYYY, h:mm:ss a") }}</span></td>
                                              <td><span class="font-14 font-weight-bold text-dark">{{orderDetail.patient.identity.surname}} {{orderDetail.patient.identity.othernames}}</span></td>
                                              <td><span class="badge badge-outline-danger p-1">{{orderDetail.patient.identity.phoneNumber}}</span></td>
                                              <td></td>
                                              <td></td>
                                          </tr>
<!--                                            <tr v-for="o in orders" :key="o.id">-->
<!--                                                &lt;!&ndash;                                            <td>{{o.orderNumber}}</td>&ndash;&gt;-->
<!--                                                <td>-->
<!--                                                    <span class="badge badge-outline-secondary p-1">{{ o.originalPrice }} </span>-->
<!--                                                </td>-->
<!--                                                <td>-->
<!--                                                    <span class="badge badge-outline-primary p-1">{{ o.price }} </span>-->
<!--                                                </td>-->
<!--                                                <td>-->
<!--                                                    <span class="badge badge-outline-danger p-1">{{ o.discountedPrice }} </span>-->
<!--                                                </td>-->
<!--                                                <td>-->
<!--                                                    <span class="text-secondary font-weight-semibold">{{ o.quantity }} </span>-->
<!--                                                </td>-->
<!--                                                <td>-->
<!--                                                    <span-->
<!--                                                        class="badge p-1"-->
<!--                                                        :class="o.status === 'PENDING_CONFIRMATION' ? 'badge-danger' : 'badge-primary'"-->
<!--                                                        >{{ o.status }}</span-->
<!--                                                    >-->
<!--                                                </td>-->
<!--                                                <td></td>-->
<!--                                            </tr>-->
                                        </tbody>
                                    </table>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-box-->
                </div>
                <div class="card mb-2">
                    <div class="card-body">
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title> <i class="mr-1"></i>Cost Details </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>Order Number</th>
                                                    <th>Payment Status</th>
                                                    <th>Original Price</th>
                                                    <th>Discounted Price</th>
                                                    <th>Delivery Price</th>
                                                    <th>Total Price</th>
                                                    <th>Payment method</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ orderDetail.orderNumber }}</td>
                                                    <td>
                                                        <span
                                                            class="p-1 badge"
                                                            :class="orderDetail.isPaid === false ? 'badge-danger' : 'badge-primary'"
                                                            >{{ orderDetail.isPaid === false ? 'NOT PAID' : 'PAID' }}</span
                                                        >
                                                    </td>
                                                    <td>
                                                        <span class="badge badge-outline-primary">GHS {{ orderDetail.originalPrice }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="badge badge-outline-danger">GHS{{ orderDetail.discountedPrice }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="badge badge-outline-info">GHS {{ orderDetail.deliveryPrice }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="badge badge-outline-success">GHS {{ orderDetail.totalPrice }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="badge badge-success p-1"> {{ orderDetail.paymentMethod }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-box-->
                </div>
                <div class="card mb-2">
                    <div class="card-body">
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title> <i class="mr-1"></i>Order Status Details </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>Order Number</th>
                                                    <th>Ordered At</th>
                                                    <th>Order Accepted</th>
                                                    <th>Rider Arrived</th>
                                                    <th>Accepted At</th>
                                                    <th>Accepted By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ orderDetail.orderNumber }}</td>
                                                    <td>
                                                        <span class="font-weight-bold font-11 text-dark">{{
                                                            orderDetail.createdAt | moment('MMMM Do YYYY')
                                                        }}</span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            class="p-1 badge"
                                                            :class="{
                                                                'badge-danger': orderDetail.isConfirmed === false,
                                                                'badge-primary': orderDetail.isConfirmed === true,
                                                            }"
                                                            >{{ orderDetail.isConfirmed === false ? 'NO' : 'YES' }}</span
                                                        >
                                                    </td>
                                                    <td>
                                                        <span
                                                            class="p-1 badge"
                                                            :class="{
                                                                'badge-danger': orderDetail.hasArrived === false,
                                                                'badge-primary': orderDetail.hasArrived === true,
                                                            }"
                                                            >{{ orderDetail.hasArrived === false ? 'NO' : 'YES' }}</span
                                                        >
                                                    </td>

                                                    <td>
                                                        <span class="font-weight-bold font-11 text-dark">{{
                                                            orderDetail.confirmedAt === null
                                                                ? '-----'
                                                                : orderDetail.confirmedAt | moment('MMMM Do YYYY')
                                                        }}</span>
                                                    </td>
                                                    <td v-if="orderDetail.isPickedUp">
                                                        <router-link
                                                            class="text-primary mr-2"
                                                            style="cursor: pointer; border-bottom: 2px dotted #029f96"
                                                            :to="'/rider/' + orderDetail.rider.id"
                                                            >{{ orderDetail.rider.identity.surname }}
                                                            {{ orderDetail.rider.identity.othernames }}</router-link
                                                        >
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-box-->
                </div>

                <div class="card mb-2" v-if="orderDetail.status === 'COMPLETED'">
                    <div class="card-body">
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title> <i class="mr-1"></i>Delivery Image </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Image</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ orderDetail.orderNumber }}</td>
                                                    <td v-if="orderDetail.deliveryImageUrl">
                                                        <enlargeable-image
                                                            :src="orderDetail.deliveryImageUrl"
                                                            :src_large="orderDetail.deliveryImageUrl"
                                                            class="resizable-image"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-box-->
                </div>
            </div>
            <!-- end col -->
        </div>
    </Layout>
</template>

<script>
import Layout from '../../../layouts/main';
import PageHeader from '@/components/Page-header';
import BaseUrl from '../../../../components/constants';
import ToastConfigAlert from '../../../../../ToastConfig';
import LottieLoader from '../../../../components/LottieLoader';
import VueQrcode from 'vue-qrcode-component';
import EnlargeableImage from '@diracleo/vue-enlargeable-image';

export default {
    name: 'ViewOrderDetail',
    components: {
        Layout,
        PageHeader,
        LottieLoader,
        'qrcode-vue': VueQrcode,
        EnlargeableImage,
    },
    data() {
        return {
            patient: {},
            prescription: [],
            orders: [],
            orderDetail: {},
            listOfProducts: [],
            qrCodeText: '', // Change this to your desired URL or text
            qrcodeOptions: {
                margin: 2,
                scale: 4,
            },
            title: 'Order details',
            items: [
                {
                    text: '',
                },
                {
                    text: 'Dashboards',
                },
                {
                    text: 'order details',
                    active: true,
                },
            ],
        };
    },
    methods: {
        printOrderDetails(){
            // Get the content of the modal
          //  const modalContent = document.getElementById('modal-sm').innerHTML;
            const modalContent = document.getElementById('my-modal-for-print').innerHTML;

            // Create a new window
            const printWindow = window.open('', '_blank');

            // Write the modal content to the new window
            printWindow.document.write(modalContent);

            // Print the window
            printWindow.print();


            // // Close the window after printing
             printWindow.close();
        },
        getPatientDetailsById() {
            BaseUrl.AxiosBearer.get(`/admin/patients/profile/${this.$route.params.Id}`)
                .then((resp) => {
                    if (resp.data.status) {
                        this.patient = resp.data.payload;
                        this.getPatientsPrescriptionsByPaitenId(patient.id);
                        this.getPatientsOrdersById();
                        this.getPharmacyOrderByOrderId();
                    }
                })
                .catch((error) => {
                    console.log('error : ', error.message);
                });
        },
        getPatientsPrescriptionsByPaitenId(patientId) {
            BaseUrl.AxiosBearer.get(`/admin/patients/profile/${patientId}/prescriptions?page=1&pageSize=100000`)
                .then((resp) => {
                    if (resp.data.status) {
                        this.prescription = [];
                        this.prescription = resp.data.payload;
                    }
                })
                .catch((error) => {
                    console.log('error : ', error.message);
                });
        },

        getPatientsOrdersById() {
            BaseUrl.AxiosBearer.get(`/admin/patients/profile/${this.$route.params.Id}/orders?page=1&pageSize=100000`)
                .then((resp) => {
                    if (resp.data.status) {
                        this.orders = [];
                        this.orders = resp.data.payload;
                    }
                })
                .catch((error) => {
                    console.log('error : ', error.message);
                });
        },
        getPharmacyOrderByOrderId() {
            BaseUrl.AxiosBearer.get(`/store/orders/get-orders/${this.$route.params.Id}`)
                .then((resp) => {
                    if (resp.data.status) {
                        this.orderDetail = resp.data.payload;
                        console.log(' Order Detail : ', this.orderDetail);
                        this.listOfProducts = resp.data.payload.carts;
                    }
                })
                .catch((error) => {
                    console.log('error : ', error.message);
                });
        },
    },
    created() {
        this.getPharmacyOrderByOrderId();
        this.getPatientDetailsById();
        this.qrCodeText = `##${this.$route.params.Id}##`;
    },
};
</script>

<style scoped>
.resizable-image {
    height: 40px;
    width: 40px;
}
@media print {
    body * {
        display: none;
    }

    .modal-content {
        display: block !important;
    }
}
</style>
